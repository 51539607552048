.main {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  height: 80vh;
  justify-content: center;
  align-items: center;
}

.nav-button {
  width: 200px;
  margin: 8px 0;
}

.link,
.link:hover {
  color: white;
  text-decoration: none;
  margin-left: 24px;
}